<template>
  <div class="mfooter-padding">
    <div class="text-center px20">
      <h2 class="title p-relative m-t-10 weight-100">
        {{ $t('SASTECH.SHOPPING_CART') }}
        <router-link
          :to="{
            name: 'ProductsM',
          }"
          tag="a"
          exact
        >
          <img
            src="/new-assets/icon/long-arrow-left-light.svg"
            alt=""
            class="left-arrow pointer"
          />
        </router-link>
      </h2>

      <h3 class="m-b-10">₪ {{ cartTotalDiscountVATPrice }}</h3>
      <h3 class="m-b-10">{{ $t('CART.INCLUDING_VAT') }}: ₪{{ cartTotalDiscountCalcVAT }}</h3>

      <!-- <div class="cart-alert">
        <div>
          <p>{{ $t('SASTECH.PAYMENT_METHOD') }}</p>
          <p>
            <b>{{ $t('TOURISM_PRODUCT.CASH') }}</b>
          </p>
        </div>
        <div>
          <p>{{ $t('SASTECH.DELIVERY_DATE') }}</p>
          <p><b>09.07.2020</b></p>
        </div>
      </div> -->

      <section v-if="cartTotalQuantity">
        <h3 class="my10">{{ $t('ASIDE.PRODUCTS') }} & {{ $t('SASTECH.PACKAGES') }} {{cartTotalQuantity}}</h3>
        <fragment>
          <template
            v-for="(product, j) in Object.values(products)"
          >
            <div
              class="sastech-product"
              :key="j"
            >
              <div
                class="d-flex align-items-center justify-content-center"
                style="width: 10%"
              >
                <img 
                  src="/new-assets/icon/trash-light.svg" 
                  alt=""
                  class="pointer"
                  @click="deleteProductToCart({ company: product.company, type: 'products', id: product.id })"
                >
              </div>
              <div
                class="d-flex align-items-center justify-content-center bg-white img-block"
                style="width: 20%"
              >
                <img
                  :src="product.image || '/new-assets/img/shaniv-logo.png'"
                  alt=""
                />
              </div>
              <div class="p-relative des-block" style="width: 70%">
                <p>{{ getTagString(product.tags) }}</p>
                <p>{{ product[currentLang + '_name'] }}</p>
                <p class="color-grey">
                  {{ product[currentLang + '_description'] }} ({{ $t('SASTECH.PACKAGE_QUANTITY') }}: {{ getProductPackageQuantity(product) }})
                </p>
                <p>
                  <b>{{ calculateProductQuantityPrice(product) }} ₪</b>
                  <span> ({{ $t('SASTECH.PACKAGE_PRICE') }} {{ getProductPackageDiscountPrice(product) }} ₪)</span>
                </p>
                <div class="slider-footer__add-btn">
                  <div
                    class="slider-footer__add-img cursor-pointer df"
                    v-on:click="onIncreaseQuality(product, 'products')"
                  >
                    <img src="/new-assets/icon/plus-circle-solid.svg" alt="" />
                  </div>
                  <div class="footer__addbtn-p df-c">
                    <p>{{ product.quantity }}</p>
                  </div>
                  <div
                    class="footer__addbtn-img cursor-pointer df"
                    v-on:click="onDecreaseQuality(product, 'products')"
                  >
                    <img src="/new-assets/icon/minus-circle-solid.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </fragment>
        <fragment>
          <template
            v-for="(product, j) in Object.values(packages)"
          >
            <div
              class="sastech-product sastech-package"
              :key="j"
            >
              <div
                class="d-flex align-items-center justify-content-center"
                style="width: 10%"
              >
                <img
                  src="/new-assets/icon/trash-light.svg"
                  alt=""
                  class="pointer"
                  @click="deleteProductToCart({ company: product.company, type: 'packages', id: product.id })"
                >
              </div>
              <div
                class="d-flex align-items-center justify-content-center bg-white img-block"
                style="width: 20%"
              >
                <img
                  :src="product.image || '/new-assets/img/shaniv-logo.png'"
                  alt=""
                />
              </div>
              <div class="p-relative des-block" style="width: 70%">
                <p></p>
                <p>{{ product.name }}</p>
                <p class="color-grey">
                  <div
                    v-if="product.products && product.products.length"
                    class=""
                    v-for="(subProduct, j) in product.products"
                    :key="j"
                    >
                    <p class="description">
                      {{ subProduct.free_text }} <span style="display: inline-block; direction: ltr">({{ subProduct.quantity }})</span>
                    </p>
                  </div>
                </p>
                <p>
                  <b>{{
                    product.DISCOUNT_VATPRICE && product.quantity
                    ? Math.round(
                    (product.quantity * product.DISCOUNT_VATPRICE +
                    Number.EPSILON) *
                    100,
                    ) / 100
                    : '-'
                    }} ₪</b>
                </p>
                <div class="slider-footer__add-btn">
                  <div
                          class="slider-footer__add-img cursor-pointer df"
                          v-on:click="onIncreaseQuality(product, 'packages')"
                  >
                    <img src="/new-assets/icon/plus-circle-solid.svg" alt="" />
                  </div>
                  <div class="footer__addbtn-p df-c">
                    <p>{{ product.quantity }}</p>
                  </div>
                  <div
                          class="footer__addbtn-img cursor-pointer df"
                          v-on:click="onDecreaseQuality(product, 'packages')"
                  >
                    <img src="/new-assets/icon/minus-circle-solid.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </fragment>
      </section>
    </div>
    <footer>
      <div class="d-flex justify-content-between align-items-center my10 w-100">
        <router-link
          :to="{
            name: 'PackagesM',
          }"
          tag="a"
          exact
          class="primary-btn add-basket_btn bg-red4 text-decoration-none"
        >
          <p>{{ $t('SASTECH.PACKAGES') }}</p>
        </router-link>
        <router-link
            :to="{
              name: 'ProductsM',
            }"
            tag="a"
            exact
            class="primary-btn add-basket_btn bg-red4 text-decoration-none"
          >
            <p>{{ $t('ASIDE.PRODUCTS') }}</p>
        </router-link>
      </div>
      
      <button class="primary-btn bg-blue add-basket_btn" @click="addToCart()">
        <p>{{ $t('SASTECH.MAKE_RESERVATION') }}</p>
      </button>
    </footer>
    
    <loading
      :active.sync="getProductsInProgress"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'CartM',
  components: {
    Loading,
  },
  data: () => {
    return {
      company: 'shaniv'
    };
  },
  computed: {
    ...mapState({
      getProductsInProgress: (state) =>
        state.productShaniv.getProductsInProgress,
      packageCounter: (state) => state.cartSystem.packageCounter,
      shanivTags: (state) => state.productShaniv.shanivTags
    }),
    ...mapGetters('cartSystem', {
      products: 'products',
      packages: 'packages',
      productsTotalQuantity: 'productsTotalQuantity',
      productsTotalPrice: 'productsTotalPrice',
      productsTotalVATPrice: 'productsTotalVATPrice',
      packagesTotalQuantity: 'packagesTotalQuantity',
      packagesTotalPrice: 'packagesTotalPrice',
      packagesTotalVATPrice: 'packagesTotalVATPrice',
      cartTotalQuantity: 'cartTotalQuantity',
      cartTotalPrice: 'cartTotalPrice',
      cartTotalVATPrice: 'cartTotalVATPrice',
      cartTotalVAT: 'cartTotalVAT',
      cartTotalDiscount: 'cartTotalDiscount',
      cartTotalDiscountVATPrice: 'cartTotalDiscountVATPrice',
      cartTotalDiscountVAT: 'cartTotalDiscountVAT',
      cartTotalDiscountCalcVAT: 'cartTotalDiscountCalcVAT',
    }),
    cart: function () {
      return this.$store.state.cartSystem.cart;
    },
    counter: function () {
      return this.$store.state.cartSystem.counter;
    }
  },
  created() {
    this.initCart();
    if (this.shanivTags.length == 0) {
      this.getShanivTags();
    }
  },
  methods: {
    ...mapActions('productShaniv', {
      getShanivTags: 'getShanivTags',
    }),
    ...mapActions('cartSystem', {
      addProductsToCart: 'addProductsToCart',
      deleteProductToCart: 'deleteProductToCart',
      deleteProductToCartByQuantity: 'deleteProductToCartByQuantity',
      initCart: 'initCart'
    }),
    onIncreaseQuality(item, type) {
      let quantity = 1;
      this.addProductsToCart({
        product: item,
        quantity: quantity,
        company: item.company,
        type: type
      });
    },
    onDecreaseQuality(item, type) {
      let quantity = 1;
      this.deleteProductToCartByQuantity({
        product: item,
        quantity: quantity,
        company: item.company,
        type: type
      });
    },
    getTagString(tagArray) {
      let tagStr = '';
      this.shanivTags.forEach((el) => {
        if (tagArray.includes(el.id)) {
          tagStr += el.name + ', ';
        }
      });
      if (tagStr != '') {
        tagStr = tagStr.slice(0, -2);
      }
      return tagStr;
    },
    getProductPackageQuantity({ id, qtySteps }) {
      return qtySteps && qtySteps > 0 ? qtySteps : 1;
    },
    getProductPackageDiscountPrice({ id, qtySteps, DISCOUNT_VATPRICE }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((DISCOUNT_VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    },
    getProductPackagePrice({ id, qtySteps, DISCOUNT_VATPRICE, VATPRICE }) {
      VATPRICE = VATPRICE >= DISCOUNT_VATPRICE ? VATPRICE : DISCOUNT_VATPRICE;
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    },
    calculateProductQuantityPrice({ DISCOUNT_VATPRICE, quantity, qtySteps }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((DISCOUNT_VATPRICE * quantity * qtySteps + Number.EPSILON) * 100) / 100;
    }
  },
};
</script>


<style lang="scss" scoped>
.left-arrow {
  position: absolute;
  right: 0px;
  top: 15px;
}
.log-filter {
  justify-content: center;
  flex-wrap: wrap;
  .logo-filter_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #234395;
    background-color: #fff;
    width: 80px;
    height: 50px;
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    img {
      width: 80%;
    }
    &.active {
      box-shadow: 0 3px 6px #222;
      border-width: 3px;
    }
  }
}
.sastech-product {
  display: flex;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  height: 100px;
  background-color: #eee;
  p {
    text-align: initial !important;
    height: 22px;
  }
  .img-block {
    border-left: 1px solid #dfdede;
    border-right: 1px solid #dfdede;
    height: 100%;
    img {
      max-height: 80%;
      max-width: 100%;
      object-fit: contain;
    }
  }
  .des-block {
    padding: 5px 10px;
  }
  &.checked {
    background-color: #f2fcff;
    border-color: #2ac0ee;
    .slider-footer__add-btn {
      background-color: #b9e3f1;
    }
    // border-top: 2px solid #2ac0ee;
    // border-bottom: 2px solid #2ac0ee;
  }
  &.sastech-package {
    height: unset;
    min-height: 100px;
  }
}
.slider-footer__add-btn {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
.add-basket_btn {
  border-radius: 20px;
  height: 38px;
  width: 45%;
  padding: 0 12px;
  font-size: 16px;
}

.cart-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: #fff;
  padding: 0 20px;
  margin-left: -20px;
  margin-right: -20px;
  div:first-child {
    text-align: right !important;
  }

  div {
    text-align: left !important;
  }
}
footer {
  flex-direction: column;
  justify-content: flex-start;
  height: 105px;
}
.mfooter-padding {
  padding-bottom: 120px;
}
</style>